import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';


import { IExchangePreviewRequest, IExchangePreviewResponseSuccess } from '../../user/interfaces/exchange-preview';
import { IExchangeDetailsResponse } from '../../exchange-direction/interfaces/exchange-details';
import { IDashboardResponse } from '../../user/interfaces/dashboard';
import { IExchangeHistoryResponse } from '../../exchange-direction/interfaces/exchange-history';
import { ICompleteVerifyOrder, ILastTransactionsResponse } from '../../home/interfaces/exchange-preview';
import { ICancelExchangeOrder, IExchangeDirection, IExchangeResponse, IFormCreateOrderData, IOrderExchangePreviewResponse, IRequestNewGatewayRequest, IRequestNewGatewayResponse, ISelectedCurrencies } from '../../home/interfaces/exchange-direction';

@Injectable({
  providedIn: 'root'
})
export class ExchangeDirectionService {

  allDirections = new BehaviorSubject<IExchangeDirection[]>([]);
  // selectedDirection = new BehaviorSubject<IExchangeDirection | null>(null);

  senderCurrencies = new BehaviorSubject<ISelectedCurrencies[]>([]);
  receiverCurrencies = new BehaviorSubject<ISelectedCurrencies[]>([]);

  selectedSenderCurrency = new BehaviorSubject<ISelectedCurrencies | null>(null);
  selectedReceiverCurrency = new BehaviorSubject<ISelectedCurrencies | null>(null);

  private _HttpClient = inject(HttpClient);

  getAllExchangeDirections(type: string = "ALL", reference: string = "5f9f9f9f9f9f9f9f9f9f9f9f"): Observable<IExchangeDirection[]> {
    const url = `${environment.endPointUrl}/v1/direction/directions?type=${type}&reference=${reference}`;
    return this._HttpClient.get<IExchangeDirection[]>(url);
  }

  /*
    in exchange home component this will recieve the data user inputs
    and will return data for exchange preview
  */

  registerExchangeOrder(formData: IFormCreateOrderData): Observable<IOrderExchangePreviewResponse> {
    return this._HttpClient.post<IOrderExchangePreviewResponse>(`${environment.endPointUrl}/v1/order`, formData);
  }

  // get the exchange order data using its Id to show them in exchange preview component
  getExchangePreviewById(id: string): Observable<IExchangeDetailsResponse> {
    return this._HttpClient.get<IExchangeDetailsResponse>(`${environment.endPointUrl}/v1/order/preview/${id}`);
  }

  // in exchange details usign this method will register request to show code for Binance
  requestBinanceCode(id: string): Observable<{ message: string, code: string }> {
    return this._HttpClient.patch<{ message: string, code: string }>(`${environment.endPointUrl}/v1/order/view-code/${id}`, {});
  }

  // when user in exchange preview we use this to send the dynamic form the user filled
  confirmExchangeOrder(id: string, body: IExchangePreviewRequest): Observable<IExchangePreviewResponseSuccess> {
    return this._HttpClient.post<IExchangePreviewResponseSuccess>(`${environment.endPointUrl}/v1/order/complete-preview-order/${id}`, body);
  }

  // to get inputs in verify exchange transaction component
  getVerifyExchangeInputs(id: string): Observable<string> {
    return this._HttpClient.get<string>(`${environment.endPointUrl}/v1/order/verify/${id}`, { responseType: 'text' as 'json' });
  }

  // verify exchange transaction step
  completeVerifyOrder(id: string, body: IExchangePreviewRequest): Observable<ICompleteVerifyOrder> {
    return this._HttpClient.post<ICompleteVerifyOrder>(`${environment.endPointUrl}/v1/order/complete-verify-order/${id}`, body);
  }

  // get last transactions in home
  getLastTransactions(): Observable<ILastTransactionsResponse[]> {
    return this._HttpClient.get<ILastTransactionsResponse[]>(`${environment.endPointUrl}/v1/order/latest-orders`);
  }

  getOrdersStats(): Observable<IDashboardResponse> {
    return this._HttpClient.get<IDashboardResponse>(`${environment.endPointUrl}/v1/order/dashboard-stats`);
  }

  // get last transactions in Last Transaction component
  getLastTransctionsOrder(page: number, limit: number, sender?: string, receiver?: string , status?: string | null): Observable<IExchangeHistoryResponse> {
    let query = '';
    // all optional filters
    if (receiver) {
      query = `&receiver=${receiver}`;
    }

    if (status) {
      query = `&status=${status}`;
    }

    if (sender) {
      query = `&sender=${sender}`;
    }

    if (receiver && status) {
      query = `&receiver=${receiver}&status=${status}`;
    }

    if (sender && status) {
      query = `&sender=${sender}&status=${status}`;
    }

    if (receiver && sender) {
      query = `&receiver=${receiver}&sender=${sender}`;
    }

    if (receiver && sender && status) {
      query = `&receiver=${receiver}&sender=${sender}&status=${status}`;
    }

    return this._HttpClient.get<IExchangeHistoryResponse>(`${environment.endPointUrl}/v1/order/order-history?page=${page}&limit=${limit}${query}`);
  }

  getCurrenciesWithPagination(page: number, limit: number): Observable<any> {
    const url = `${environment.endPointUrl}/v1/currency/get?page=${page}&limit=${limit}`;
    return this._HttpClient.get<any>(url);
  }

  requestNewGateway(formData: IRequestNewGatewayRequest): Observable<IRequestNewGatewayResponse> {
    const url = `${environment.endPointUrl}/v1/new-gateway/request-new-gateway`;
    return this._HttpClient.post<IRequestNewGatewayResponse>(url, formData);
  }

  // cancel Exchange Order
  cancelExchangeOrder(id: string): Observable<ICancelExchangeOrder> {
    return this._HttpClient.patch<ICancelExchangeOrder>(`${environment.endPointUrl}/v1/order/cancel-order/${id}`, {});
  }

}
